import React, { useState, useRef } from 'react';
import '../../src/components/styles.scss';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from '@mui/material/Typography';

export default function Samples() {
  // State and Refs for "Pussy Productions" sample
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [currentTime1, setCurrentTime1] = useState(0);
  const [duration1, setDuration1] = useState(0);
  const audioRef1 = useRef(null);

  // State and Refs for "Rock Star" sample
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [currentTime2, setCurrentTime2] = useState(0);
  const [duration2, setDuration2] = useState(0);
  const audioRef2 = useRef(null);

  // State and Refs for "The Gentle Resonance" sample
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [currentTime3, setCurrentTime3] = useState(0);
  const [duration3, setDuration3] = useState(0);
  const audioRef3 = useRef(null);

  const audioFiles = [
    '/assets/media/pussy_production_sample-2min.mp3',
    '/assets/media/Rock_Star_sample.mp3',
    '/assets/media/the-gentle-res_sample.mp3',
  ];

  const togglePlay = (audioRef, isPlaying, setIsPlaying, setCurrentTime, setDuration) => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);

    // Update time and duration for the current audio
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleTimeUpdate = (audioRef, setCurrentTime, setDuration) => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleProgressChange = (e, audioRef, setCurrentTime) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (e, audioRef) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
  };

  return (
    <div>
      <Grid container className='aboutbody'>
        <Grid item>
          <CardMedia 
            className='topmdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" className='aboutTitle'>
            Audiobook Samples:
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word",  marginBottom: '10px'  }}>
            <i>(Titles also available from "Shop" or "Book" menu, and via major distribution outlets)</i>            
          </Typography>

          {/* The Art of Meditation: The Gentle Resonance - Audiobook Sample */}
          <div className='sampleBg3'>
            <h2>"The Art of Meditation: The Gentle Resonance" Audiobook Sample (1 minute)</h2>
            <audio 
              ref={audioRef3} 
              src={audioFiles[2]}
              onTimeUpdate={() => handleTimeUpdate(audioRef3, setCurrentTime3, setDuration3)}
            />
            <button onClick={() => togglePlay(audioRef3, isPlaying3, setIsPlaying3, setCurrentTime3, setDuration3)}>
              {isPlaying3 ? 'Pause' : 'Play'}
            </button>
            <input
              type="range"
              value={currentTime3}
              max={duration3}
              onChange={(e) => handleProgressChange(e, audioRef3, setCurrentTime3)}
            />
            <span>{`${Math.floor(currentTime3)}s / ${Math.floor(duration3)}s`}</span>
            <div>
              <label>Volume:</label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                defaultValue="1"
                onChange={(e) => handleVolumeChange(e, audioRef3)}
              />
            </div>
            <div style={{ marginTop: '20px', marginBottom: '10px' }}>                
                <a href="https://markdkberry.gumroad.com/l/thegentleresonance" target="_blank" rel="noopener noreferrer" className="purchase-link-0">
                  Click Here To Download "The Art of Meditation: The Gentle Resonance" Audiobook From Gumroad
                </a>
              </div>
          </div>

          {/* Pussy Productions Audiobook Sample */}
          <div className='sampleBg2'>
            <h2>"Pussy Productions" Audiobook Sample (2 minutes)</h2>
            <audio 
              ref={audioRef1} 
              src={audioFiles[0]} 
              onTimeUpdate={() => handleTimeUpdate(audioRef1, setCurrentTime1, setDuration1)} 
            />
            <button onClick={() => togglePlay(audioRef1, isPlaying1, setIsPlaying1, setCurrentTime1, setDuration1)}>
              {isPlaying1 ? 'Pause' : 'Play'}
            </button>
            <input
              type="range"
              value={currentTime1}
              max={duration1}
              onChange={(e) => handleProgressChange(e, audioRef1, setCurrentTime1)}
            />
            <span>{`${Math.floor(currentTime1)}s / ${Math.floor(duration1)}s`}</span>
            <div>
              <label>Volume:</label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                defaultValue="1"
                onChange={(e) => handleVolumeChange(e, audioRef1)}
              />
            </div>
            <div style={{ marginTop: '20px', marginBottom: '10px' }}>                
                <a href="https://markdkberry.gumroad.com/l/pussyproductions" target="_blank" rel="noopener noreferrer" className="purchase-link-0">
                  Click Here To Download "Pussy Productions" Audiobook From Gumroad
                </a>
              </div>
          </div>

          {/* Rock Star Audiobook Sample */}
          <div className='sampleBg1'>
            <h2>"Rock Star" Audiobook Sample (1 minute)</h2>
            <audio 
              ref={audioRef2} 
              src={audioFiles[1]} 
              onTimeUpdate={() => handleTimeUpdate(audioRef2, setCurrentTime2, setDuration2)}
            />
            <button onClick={() => togglePlay(audioRef2, isPlaying2, setIsPlaying2, setCurrentTime2, setDuration2)}>
              {isPlaying2 ? 'Pause' : 'Play'}
            </button>
            <input
              type="range"
              value={currentTime2}
              max={duration2}
              onChange={(e) => handleProgressChange(e, audioRef2, setCurrentTime2)}
            />
            <span>{`${Math.floor(currentTime2)}s / ${Math.floor(duration2)}s`}</span>
            <div>
              <label>Volume:</label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                defaultValue="1"
                onChange={(e) => handleVolumeChange(e, audioRef2)}
              />
            </div>
            <div style={{ marginTop: '20px', marginBottom: '10px' }}>                
                <a href="https://markdkberry.gumroad.com/l/rockstar-audiobook" target="_blank" rel="noopener noreferrer" className="purchase-link-0">
                  Click Here To Download "Rock Star" Audiobook From Gumroad
                </a>
              </div>
          </div>

        </Grid>
        <Grid item>          
          <CardMedia 
            className='bottommdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
      </Grid>  
    </div>
  );
}



import React from "react";


export default function Artwork () {
  return (
    <div  className='iframe-container'>
      <iframe 
        title="Digital Artwork by Mark DK Berry" 
        name="Framename" 
        src="https://markdkberry.artstation.com/albums/7947579" 
        height="100%"
        width='100%'
        frameBorder="0" 
        scrolling="yes" 
        >   
      </iframe>
    </div>
  );
}
import React from "react";
import '../../src/components/styles.scss';

export default function Poetry () {
  return (
    <div>
      <iframe 
        title="Lost In A Sleeping Garden" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B0CXLXNLJR" 
        height="340"
        width='100%'
        //frameBorder="0" 
        //scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
      <iframe 
        title="A to Z" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B09SG4BQSX" 
        height="340"
        width='100%'
        //frameBorder="0" 
        //scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
      <iframe 
        title="Broke" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B07SD8FFMG" 
        height="340"
        width='100%'
        //frameBorder="0" 
        //scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
      <iframe 
        title="Leaving Town" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B07PGCTL3T" 
        height="340"
        width='100%'
        //frameBorder="0" 
        //scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
    </div>
  );
};
import React from "react";
import '../../src/components/styles.scss';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from '@mui/material/Typography';


export default function About () {
  return (
    <div>
      <Grid container className='aboutbody'>
        <Grid item>
          <CardMedia 
            className='topmdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
          <Grid item>
          <Typography variant="h4" className='aboutTitle'>
          STAGE PLAYS
          </Typography> 
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i>For scripts or to discuss options for stage plays or musicals email <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b></i>   
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          The Snake Pit
          </Typography>
            <Grid item>          
            <CardMedia 
                className='stageplay340'
                image={`${process.env.PUBLIC_URL}/assets/images/TheSnakePit_Cover_1_small340.jpg`}
            /> 
            </Grid>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          <b><i>(A full length mp3 audio staged cast recording of this stage play is available to stream for free on <a href="https://archive.org/details/the-snake-pit-markdkberry-2024" target="_blank" rel="noopener noreferrer">Internet Archive</a>.<br></br>The script in eBook format is available for consideration on <a href="https://www.amazon.com/stores/Mark-DK-Berry/author/B082WGCJQ6" target="_blank" rel="noopener noreferrer">Amazon</a>.)</i></b><br></br><br></br> 
          - An Intimate Dramedy.<br></br>- Length: 1 to 1.5 hours (Three Acts).<br></br>- Adult Themes. Some bad language. Sexual themes implied but not explicit. <br></br>- Cast: 4 main (2F, 3M), 1 (M) supporting. <br></br><br></br>- Logline: <i>“Amidst a mysterious storm at Glastonbury Festival, two couples find themselves swept into an experiment that will test the boundaries of love, desire, and fidelity.”</i><br></br><br></br>- Synopsis: <i>"A married couple in their thirties find themselves in a comfortable tent atop a hill at the Glastonbury Festival, UK. As a sudden storm hits, they offer shelter to another couple. An enigmatic stranger briefly appears, delivering cryptic remarks before vanishing. The night takes an unexpected turn as the couples begin exploring uncharted territory in their relationships. By morning, the dynamics have shifted dramatically. Six months later, in Tulum, Mexico, the two couples, now with different partners, end up in neighbouring beachfront chalets. A chance encounter on the beach leads the husband and wife to reflect on their journey and the possibility of reconciliation before it's too late."</i>     
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          The Highwayman
          </Typography>
            <Grid item>          
            <CardMedia 
                className='stageplay600'
                image={`${process.env.PUBLIC_URL}/assets/images/Highwayman_cover_1-BW-small640.jpg`}
            />
            </Grid>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          <b><i>(An mp3 audio recording of this stage play is available on request for agents or producers.)</i></b><br></br><br></br> 
          - A Period Drama.<br></br>- Length: 1.5 to 2 hours (Five Acts).<br></br>- Features violence. No bad language or explicit sexual content. <br></br>- Cast: 6 main (4M, 2F), 4 supporting (2M, 2F), small ensemble of extras.<br></br><br></br>- Synopsis: <i>"In 17th century England, a young highwayman plans a daring stagecoach robbery, only to find his ex-wife onboard, along with a valuable jewel and mysterious documents. The papers reveal a nefarious plot by her current husband, a tyrant lord, to destroy their village. As the highwayman navigates betrayal, murder, and intense confrontations, he must confront his past and make devastating choices. The play explores themes of love, revenge, and redemption against a backdrop of dramatic twists and emotional stakes. Will the highwayman find justice and reclaim a lost love, or will the quest for vengeance lead to his own downfall?"</i>    
          </Typography> 

          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i>For scripts or to discuss options for stage plays or musicals email  <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b></i>   
          </Typography>

          <Typography variant="h3" className='aboutTitle'>
          The Folly of Poly Amore
          </Typography>
            <Grid item>          
            <CardMedia 
                className='stageplay640'
                image={`${process.env.PUBLIC_URL}/assets/images/The_Folly_small-1.jpg`}
            /> 
            </Grid>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          <b><i>(An mp3 audio recording of this stage play is available on request for agents or producers.)</i></b><br></br><br></br> 
          - A tongue in cheek drama on the challenges of polyamory and the Tantra scene.<br></br>- Length: 1.75 to 2 hours (Three Acts).<br></br>- Adult Themes. Some bad language. Sexual themes implied but not explicit. <br></br>- Cast: 6 main (1M, 5F). 6 supporting (3F, 3M). Ensemble of extras optional. <br></br><br></br>- Logline: <i>“A man discovers polyamory through a transformative Tantric experience, embarking on a seductive but complicated journey that challenges his perceptions of love and intimacy.”</i><br></br><br></br>- Synopsis: <i>"A middle-aged man's relationship implodes, forcing him to move out of his family home. After spending time alone in a new city, he finds himself drawn into a transformative encounter with several women at a Tantra event—an experience that shifts his perspective. Could polyamory be the answer? In the aftermath, an almost magical opportunity presents itself, unknowingly orchestrated by the woman who controls the Tantric sisterhood. Initially, things go well, but soon bumps appear, leading to bigger issues and, eventually, serious problems. As the situation unravels, hidden truths come to light. Will he navigate the polyamory journey successfully, or will it ultimately undo him?"</i>     
          </Typography>

          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i>For scripts or to discuss options for stage plays or musicals email  <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b></i>   
          </Typography> 
          <br></br><br></br>
        </Grid>
        <Grid item>          
          <CardMedia 
            className='bottommdkb'            
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
      </Grid>  
    </div>
  )
}

import React from "react";
import '../../src/components/styles.scss';

export default function Books () {
  return (
    <div>
      <iframe 
        title="The Art of Meditation: The Gentle Resonance" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B0D8D9P9J5&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_T94XCXHWF9XZ71H8KE59" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        /*loading="lazy" - disabled for first loading item only */ 
        >   
      </iframe>
      <iframe 
        title="Pussy Productions" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B07NJQX3MP&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_Z9HBXKDKXN43T5EYWZTM" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
      <iframe 
        title="Infinite Time" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B0C2K7JJBS&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_KAADPHG7B6JZMN7S53K5" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        loading="lazy"
        >   
      </iframe>
      <iframe 
        title="Seven Nights In Morocco" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B09R7RP8WH&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_0P6Y0Y3MQEN4E2GVQRF7" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        loading="lazy"
        >   
      </iframe>
        <iframe 
        title="Rock Star" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B09N6X45V8&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_S9HVHXSWMEE123ZFX99E" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        loading="lazy" 
        >   
      </iframe>
      <iframe 
        title="The Road To El Palmar" 
        name="Framename" 
        src="https://read.amazon.com.au/kp/card?asin=B082V6ZJW1&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_E0HQHQ0SHHAF17AYJTZ4" 
        height="340"
        width='100%'
        frameBorder="0" 
        scrolling="yes"
        loading="lazy" 
        >   
      </iframe>

    </div>
  );
};
import React from 'react';
import './components/styles.scss';


export default function Footer() {
    return (
      <div padding-bottom= '60px'>
        <div className='footer'>
          <div className='footerText'>
            © 2024 - Mark DK Berry
          </div>
        </div>
      </div>
    )
}
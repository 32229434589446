import React from "react";
import Link from '@mui/material/Link';
import '../../src/components/styles.scss';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";
//import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faArtstation } from "@fortawesome/free-brands-svg-icons";
//import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { faMastodon } from '@fortawesome/free-brands-svg-icons';
import { faGoodreads } from '@fortawesome/free-brands-svg-icons';
//import { ReactComponent as RumbleIcon } from './public/assets/images/RumbleIcon.svg';
//import mdkbImg from './images/markberry-circle-alpha.png';



export default function About () {
  const handleIconClick = () => {
    window.open('https://rumble.com/c/c-1331684', '_blank');
  };
  const handleIconClick2 = () => {
    window.open('https://rumble.com/c/c-5236091', '_blank');
  };
  return (
    <div>
      <Grid container className='aboutbody'>
        <Grid item>
          <CardMedia 
            className='topmdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
          <Grid item>
          <Typography variant="h4" className='aboutTitle'>
          Social Media Links
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#4267B2'}} icon={faFacebook}  title="Facebook Music Page" onClick={event =>  window.open('https://www.facebook.com/markdkberry.music', '_blank')}/>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#4267B2'}} icon={faTwitter}  title="Twitter Music Profile" onClick={event =>  window.open('https://twitter.com/markdkberry', '_blank')}/>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#4267B2'}} icon={faMastodon}  title="Fediverse/Mastodon Music Profile" onClick={event =>  window.open('https://mstdn.social/@markdkberry', '_blank')}/>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#4267B2'}} icon={faSoundcloud}  title="SoundCloud Music Profile" onClick={event =>  window.open('https://soundcloud.com/markdkberry', '_blank')}/>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#E1306C'}} icon={faInstagram}  title="Instagram Art Profile" onClick={event =>  window.open('https://www.instagram.com/markdkberry/', '_blank')} />

            <div className="faIcon-hover" style={{ fontSize: '4em', color: '#4267B2', marginRight: '0.1em' }} onClick={handleIconClick} title="Music Videos on Rumble">
              <img src="/assets/images/RumbleIcon.svg" alt="Music Videos on Rumble" style={{ width: '1em', height: '1em', maxWidth: '100%' }} />
            </div>

            <div className="faIcon-hover" style={{ fontSize: '4em', color: '#4267B2', marginRight: '0.1em' }} onClick={handleIconClick2} title="DJ Mixes on Rumble">
              <img src="/assets/images/RumbleIcon.svg" alt="DJ Mixes on Rumble" style={{ width: '1em', height: '1em', maxWidth: '100%' }} />
            </div>

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#E1306C'}} icon={faArtstation}  title="ArtStation Profile" onClick={event =>  window.open('https://www.artstation.com/markdkberry', '_blank')} />

            <FontAwesomeIcon className='faIcon-hover' style={{fontSize:'4em', color: '#E1306C'}} icon={faGoodreads}  title="Goodreads Profile" onClick={event =>  window.open('https://www.goodreads.com/author/show/18558458.Mark_D_K_Berry', '_blank')} />
          </div>

          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i><span style={{ backgroundColor: "blue" }}>UPDATE SEPTEMBER 2024:</span> 
            <br></br><br></br>
            To discuss options for stage plays or musicals email <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b>
            <br></br><br></br>
            Audiobooks are available on <b><a href="https://markdkberry.gumroad.com/" target="_blank" rel="noopener noreferrer">Gumroad (Shop)</a></b>, music is on <b><a href="https://markdkberry.bandcamp.com" target="_blank" rel="noopener noreferrer">Bandcamp (Music)</a></b>, and music videos are on <b><a href="https://rumble.com/c/c-1331684" target="_blank" rel="noopener noreferrer">Rumble (Videos)</a></b>. All books are available on <b><a href="https://www.smashwords.com/profile/view/markdkberry" target="_blank" rel="noopener noreferrer">Smashwords</a></b> and <b><a href="https://www.amazon.com/stores/Mark-DK-Berry/author/B082WGCJQ6" target="_blank" rel="noopener noreferrer">Amazon</a></b>.
            <br></br><br></br>
            (I do not currently release music to Spotify or YouTube due to their dubious royalty payment structure: Independant musicians are not getting properly paid.)</i>        
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          On Stage Plays & Musicals
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "I have recently started work on Stage Plays and Musicals. I wrote my first Musical in 1987 called "The Search" and have since produced a number of concept albums that were initially based on visual narratives <i>(Eurydice, City Of Lights, The Other Side, Black Magic, Songs To Swing The Gallows)</i>. With AI getting closer to being able to produce "text-to-video", it is time for me to revisit the musical ideas.<br></br><br></br>The rest of 2024 and 2025 will be spent working on those to get them into working scripts, libretto, and sheet music scores. Whether they ever see production on a stage remains to be seen. I am excited to see where it goes. As for the stage plays, that is a new area for me and something I am finding interesting to explore further. I have two finished stage plays <i>(The Highwayman, The Snake Pit)</i> and if anyone is interested in producing them get in touch via email for a copy of the script. More will be added to the links as I complete them."
          <br></br>
          <span style={{ backgroundColor: "blue" }}>I am using markdown for roughing up the ideas between devices, and then Scrivener with a script template to produce the stage play or musical to PDF. I use Reaper for writing music and MuseScore for scoring the parts.</span>        
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          On AI Versus Creativity
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "It may seem strange, but I am a big fan of AI. However, I am NOT a big fan of what Big Tech and our Governments will do with it. AI has definitely impacted all areas of my creativity, and in many ways negatively. But, I have always been a creative, and AI won't change that. Creativity is not about making money or making a living, it is about creating. Therefore AI is not an issue to me, I'll just use it to augment my creativity. I came to terms with "why" I was creative years ago, and I will always be a creative.<br></br><br></br>I am currently mucking about with AI using Python, Ollama, and local LLMs, and will post projects to the blog. I'll also go into greater detail of why I think AI is not the problem. I believe human's are the real problem, and probably not for the reasons you think. <br></br><br></br>All of my creative work is produced by my hand, but I am increasingly using AI as a tool to support that creativity. I now use AI for grammar and spell-checking, and I'm currently developing an AI project for proof-reading my books. So, there could be a case made that I am not working alone, but using AI as an editor. It features in my music mixing by default too, I think Neuron plugin uses AI, and possibly my mastering company. I am eager to use AI for "text to video" to develop music ideas, but it's just not there yet. I am also using Krita with ComfyUI AI plugin occasionally. AI is just another tool for the creative to use creatively."
          <br></br>
          <span style={{ backgroundColor: "blue" }}>I highly recommend migrating to Linux (see my blog for more suggestions). AI is turning us all into product and will mine everything we do for marketing data. Apple and Microsoft are not your friend. It is time to develop services locally, and get away from Big Tech where we can.</span>        
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          On Digital Art
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "I have only just started dabbling in digital art and the Artwork page was added to this site in late 2022. Follow me on <Link href='https://www.instagram.com/markdkberry/' target="_blank">Instagram@MarkDKBerry</Link> and on <Link href='https://www.artstation.com/markdkberry' target="_blank">Artstation.com/MarkDKBerry</Link> . I am still finding my way with it and exploring different styles. I currently use Krita software with a Wacom Intuos pen tablet, and yes it is all hand drawn (not AI) though I use a photo reference in most cases. I really like doing portraits, but find landscapes too tedious except for menacing seascapes. There is a lot to learn having never done much art before. Come back in a year or two and I will probably have figured out exactly what my preferred style is."
          <br></br>
          <span style={{ backgroundColor: "blue" }}>Equipment used: Krita paint Software (sometimes Gimp) with Wacom Intuos pen-tablet. Also now ComfyUI AI plugin for Krita.</span>        
          </Typography> 
          <Typography variant="h3" className='aboutTitle'>
          On Being A Musician
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>          
          "I spent most of my life in England trying to be a musician, playing in <Link href='https://markdkberry.bandcamp.com/album/the-way-uk-1998-2004' target="_blank">bands in the UK</Link> from a young age up to my late 30's. It's a young man's game and there comes a point where you start to wonder what you are doing prancing around on a stage. I gave up the bands and the gigging when I got to Australia in 2007, and not long after that I made the decision to give up music altogether.           
          </Typography> 
          <Typography variant="body1" className='aboutText'>        
          Within months of that decision I got signed up to a record deal with <Link href='https://hospitalrecords.bandcamp.com/track/tough-luck' target="_blank">Hospital Records along with Royalston</Link> on a Drum and Bass collaboration and that lit a fire under me again. So I did <Link href='https://www.youtube.com/watch?v=96wbt3dk2Og' target="_blank">a few more tracks with him</Link>, and we had releases <Link href='https://www.youtube.com/watch?v=No1L41HXotc' target="_blank">on other labels too</Link>. I didn't get rich and I didn't 'make it big' but it was nice to have a taste of success. I continue to write and produce music, but now I prefer to self-publish my work. 
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          The music never leaves me though I do take time off occasionally. 2019-2020 became more about writing books but 2021 drew me back to making and producing music again. I also discovered a penchant for making music videos and those are being migrated to <Link href='https://rumble.com/c/c-1331684' target="_blank">Rumble Music Video channel</Link>."
          <br></br>
          <span style={{ backgroundColor: "blue" }}>Equipment used: Reaper DAW for music (ex. Logic and Cubase). For Video production I was using Reaper but now use Davinci Resolve and Unreal Engine.</span>  
        </Typography>
        <Typography variant="h3" className='aboutTitle'>
          On The Music Industry
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "I am not a big fan of the music industry from the artist's point of view. I watched it use artists through the 90's and it has only gotten worse. We all get ripped off at some point in the game, and it's a horrible feeling when it happens because someone else then owns your music and makes money from it.
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          As such, I no longer believe that selling copyright is the right thing to do, it is a mistake that I have regretted every time I did it. Now I would rather self-release and retain control of my artistic direction than hand over ownership of copyright to a record company.
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          The music business is a cold machine and it tricks musicians into thinking that 'making it' is a possibility, but it's a myth. Making your own music isn't something you will make a living from, very few do, and fame is a destructive force. It's more important to make music for the love of it while retaining your ownership, and it took me a long time to figure that out. 
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          I spent years working in the business side of the music industry during the 90's, and I really loved it from that side. I worked as road crew at The Marquee Club, Town & Country Club, Wembley and other venues in and around London. Eventually, I went on to become the first CAD designer to work for <Link href='https://www.brilliantstages.com/' target="_blank">Brilliant Stages</Link> and the late great Mark Fisher who designed touring shows for the likes of The Rolling Stones, Pink Floyd, U2, Take That, and hundreds of other bands. It was a fun side of the industry to be in. I got well paid and I got into gigs for free. I loved my time in it, but in the end I was done with that side of it too.
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          I'll talk more about the music industry, music production, and the rock and roll lifestyle in my blog. It is also the subject of some of my satirical fiction books: (<Link href='https://www.smashwords.com/profile/view/markdkberry' target="_blank">"Rock Star"</Link> was published in 2021 and the sequel "Impresario" is currently underway, but I don't expect to release it before late 2024)." 
        </Typography>
        <Typography variant="h3" className='aboutTitle'>
          On Writing Books
          </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "I love writing, I find it more satisfying than making music. I love making music, but I can never finish songs with quite the same certainty as finishing a book. I have a lot of books that are written but need to be tidied up before being published. It's a long job.
          </Typography>
          <Typography variant="body1" className='aboutText'> 
          I have been publishing books since about 2017 in several genres: <i>travel non-fiction, satirical fiction, roman-à-clef, poetry, and sci-fi.</i> Follow me on <Link href='https://www.goodreads.com/author/show/18558458.Mark_D_K_Berry' target="_blank">Goodreads</Link> where I plan to have giveaways in exchange for honest reviews (love or hate my work, I will still appreciate you reviewing it!).
          </Typography> 
          <Typography variant="body1" className='aboutText'>        
          Writing began for me as a teenager, but I only started writing prose in my thirties, and books in my fifties. Before that, most of what I wrote turned into poetry and lyrics. I was pretty prolific and I have a ton of it. Literally. It's all hand written into notepads and books that live in a big black box that has followed me around the world. I am working on getting them into printed form just so I can get rid of that box. I doubt I will ever finish the task. I have managed to publish a few poetry books so far, but there is enough in that box for a hundred."
          </Typography>
        <Typography variant="h3" className='aboutTitle'>
          On Travel Writing
        </Typography>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          "I have been travel-writing for a long time, but always use pen and paper to journal when I travel wild. So I have a lot of stuff written up and ready to go from past decades, now it just needs transfering to the computer and polishing up. An especially long job and not something I can palm off onto anyone because my hand-writing is so bad.
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          I love adventures and trips into wild places, and I've made a few. The first I journalled properly was <Link href='https://www.smashwords.com/books/view/1133169' target="_blank">"The Road To El Palmar"</Link> which was a trip down the west coast of Spain with just a guitar and a tent. I've also published <Link href='https://www.smashwords.com/books/view/1130194' target="_blank">"Seven Nights In Morocco"</Link> written while camping in the deserts of Morocco dealing with rabid dogs, wild cats, and getting lost in sand dunes. I have others I have to work on; a cycle trip across the outback of Australia; my time spent living in a van in London; and my time in Los Angeles. It's a long task for each book to be made publication ready.
          </Typography> 
          <Typography variant="body1" className='aboutText'>  
          The adventuring never stopped and I was about to head to the wilds of Africa when COVID hit us. It's going to be a few years to recover from the impact of that, but more travel-writing will be in order when we do. I really miss it."
          <br></br>
          <span style={{ backgroundColor: "blue" }}>Equipment used: Scrivener for publication-ready books & whatever phone or notepad I have on me at the time for jotting down ideas or plotting story arcs. When travelling I prefer pen and paper to a computer. I use ProWritingAid for grammar and spell checks. I am developing an AI project for proof-reading duties. I have also switched to writing in Markdown. I use Obsidian for that, and then use GitLab for version control of my book writing, and finally back to Scrivener for the publication compile.</span>    
        </Typography>
        </Grid>
        <Grid item>          
          <CardMedia 
            className='bottommdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
      </Grid>  
    </div>
  )
}

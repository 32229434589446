import React from "react";


export default function Blog () {
  return (
    <div className='iframe-container'>
      <iframe 
        title="My Blog" 
        name="Framename" 
        src="https://markdkberry.home.blog/" 
        height="100%"
        width='100%'
        frameBorder="0" 
        scrolling="yes" 
        >   
      </iframe>
    </div>
  );
}
import React from "react";
import '../../src/components/styles.scss';

export default function Shop () {
    return (
      <div className='iframe-container'>
        <iframe 
          title="Gumroad Shop" 
          name="Framename" 
          src="https://markdkberry.gumroad.com/" 
          height='100%' //mbedit was "height="640"" but its not filling the page well in browser.
          width='100%'
          frameBorder="0" 
          scrolling="yes" 
          >   
        </iframe>
      </div>
    );
  };


/* eslint-disable jsx-a11y/anchor-is-valid */ 
//The above line suppresses the warnings that appear without it regard anchors
import React from 'react';
import ImageGallery from 'react-image-gallery';
import '../../src/components/image-gallery.scss';
//import '../../src/components/Djsets.css';


/* github here - https://github.com/xiaolin/react-image-gallery and an example of how the original looks - https://www.linxtion.com/demo/react-image-gallery/ */

/* example video embed - embedUrl: 'https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0', */

// its recommended to put local image files in public folder as opposed to under /src - https://stackoverflow.com/questions/39999367/how-do-i-reference-a-local-image-in-react


const PREFIX_URL = 'https://f4.bcbits.com/img/'; //* bandcamp servers, seems to work best using external links*//


class Videos extends React.Component {

  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'top',
      showVideo: {},
      useWindowKeyDown: true,
    };

    this.images = [
      {
        thumbnail: `${process.env.PUBLIC_URL}/assets/images/Rumble_markdkberry.png`, // Intro To MarkDKBerry YT - video
        original: `${process.env.PUBLIC_URL}/assets/images/Rumble_markdkberry.png`,
        //embedUrl: 'https://www.youtube.com/@markdkberry/videos',
        //description: 'www.YouTube.com/@markdkberry',
        //renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a2966793906_16.jpg`, // Fallen Angel - video
        original: `${PREFIX_URL}a2966793906_16.jpg`,
        embedUrl: 'https://rumble.com/embed/v3skfj0/?pub=woq9s',
        description: '"Fallen Angel" - EDM Music Video was made using UnrealEngine5 and MetaHumans',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a1898273277_16.jpg`, // All I Feel - video
        original: `${PREFIX_URL}a1898273277_16.jpg`,
        embedUrl: 'https://rumble.com/embed/v3skebq/?pub=woq9s',
        description: '"All I Feel" - Video of 1970s Mashup set to EDM Melodic House track ',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a1497001937_16.jpg`, // Counterfeit Love - video
        original: `${PREFIX_URL}a1497001937_16.jpg`,
        embedUrl: 'https://rumble.com/embed/vq015m/?pub=woq9s',
        description: '"Counterfeit Love" - Video of dancers in different eras set to EDM Melodic House track',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a3996907725_16.jpg`, // The Ape Never Will - video
        original: `${PREFIX_URL}a3996907725_16.jpg`,
        embedUrl: 'https://rumble.com/embed/vpzz1s/?pub=woq9s',
        description: '"The Ape Never Will" - Mashup of "The Planet of the Apes" original movie set to EDM Melodic House track',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a2405639589_16.jpg`, // The Children Of Spring (Eurydice) - video
        original: `${PREFIX_URL}a2405639589_16.jpg`,
        embedUrl: 'https://rumble.com/embed/vpzwg6/?pub=woq9s',
        description: '"The Children Of Spring" - Montage flow of classic artwork set to a Classical music piece',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a0266091644_16.jpg`, // Starships - video
        original: `${PREFIX_URL}a0266091644_16.jpg`,
        embedUrl: 'https://rumble.com/embed/v3s77wh/?pub=woq9s',
        description: '"Starships" - Mashup video of "Passengers" set to Chillout music',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${PREFIX_URL}a2750735888_16.jpg`, // Waltz In Black - video
        original: `${PREFIX_URL}a2750735888_16.jpg`,
        embedUrl: 'https://rumble.com/embed/v3s73f5/?pub=woq9s',
        description: '"Waltz In Black" - Mashup of "Legend 1985" fantasy film scene of the dance. Set to a modern classical piece',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${process.env.PUBLIC_URL}/assets/images/She - The Way UK.jpg`, // "She" By The Way - VIMEO video
        original: `${process.env.PUBLIC_URL}/assets/images/She - The Way UK.jpg`,
        embedUrl: 'https://rumble.com/embed/v3s6nyn/?pub=woq9s',
        description: '"She" - Video from The Way UK (Band) - **Adult Content Be Advised** ',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `${process.env.PUBLIC_URL}/assets/images/NoRest_21_Cover2.jpg`, // "No Rest" - VIMEO video
        original: `${process.env.PUBLIC_URL}/assets/images/NoRest_21_Cover2.jpg`,
        embedUrl: 'https://rumble.com/embed/v3rlvut/?pub=woq9s',
        description: '"No Rest" - Indie music video with dark undertones',
        renderItem: this._renderVideo.bind(this)
      },

      /* The below was the original that was array mapping the images using "".concat(this._getStaticImages());" at the end of "this.images ="" function
      {
        original: `${PREFIX_URL}1.jpg`,
        thumbnail: `${PREFIX_URL}1t.jpg`,
        originalClass: 'featured-slide',
        thumbnailClass: 'featured-thumb',
        //description: 'Custom class for slides & thumbnails',
      },*/
    ].concat(this._getStaticImages());
  }

  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({[state]: event.target.value});
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }

  _getStaticImages() { //This used to collect the images using iteration, but now just populates the thumbnail positions with nothing, but adds the circles
    let images = [];
    for (let i = 2; i < 2; i++) { //originally was  "i < 12" - might be able to remove this entire section, but leaving it for now
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail:`${PREFIX_URL}${i}t.jpg`
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    const showVideo = this.state;
    this.setState({
      showVideo: {
        ...showVideo,
        [url]: !showVideo[url]
      }
    });

    if (!showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <a
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </a>
                <iframe
                  title='video player'
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>              
              <img className='image-gallery-image' src={item.original} alt='video'/>
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (
      <div className='home'>
      <section className='app'>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={this.state.useWindowKeyDown}
        />
      {/*
        <div className='filler-box' title="Filler when sandbox is missing"></div>       

        <div className='app-sandbox'>

          <div className='app-sandbox-content'>
            <h2 className='app-header'>Settings</h2>

            <ul className='app-buttons'>
              <li>
                <div className='app-interval-input-group'>
                  <span className='app-interval-label'>Play Interval</span>
                  <input
                    className='app-interval-input'
                    type='text'
                    onChange={this._handleInputChange.bind(this, 'slideInterval')}
                    value={this.state.slideInterval}/>
                </div>
              </li>

              <li>
                <div className='app-interval-input-group'>
                  <span className='app-interval-label'>Slide Duration</span>
                  <input
                    className='app-interval-input'
                    type='text'
                    onChange={this._handleInputChange.bind(this, 'slideDuration')}
                    value={this.state.slideDuration}/>
                </div>
              </li>

              <li>
                <div className='app-interval-input-group'>
                  <span className='app-interval-label'>Thumbnail Bar Position</span>
                  <select
                    className='app-interval-input'
                    value={this.state.thumbnailPosition}
                    onChange={this._handleThumbnailPositionChange.bind(this)}
                  >
                    <option value='bottom'>Bottom</option>
                    <option value='top'>Top</option>
                    <option value='left'>Left</option>
                    <option value='right'>Right</option>
                  </select>
                </div>
              </li>
            </ul>

            <ul className='app-checkboxes'>
              <li>
                <input
                  id='infinite'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'infinite')}
                  checked={this.state.infinite}/>
                  <label htmlFor='infinite'>allow infinite sliding</label>
              </li>
              <li>
                <input
                  id='show_fullscreen'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showFullscreenButton')}
                  checked={this.state.showFullscreenButton}/>
                  <label htmlFor='show_fullscreen'>show fullscreen button</label>
              </li>
              <li>
                <input
                  id='show_playbutton'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showPlayButton')}
                  checked={this.state.showPlayButton}/>
                  <label htmlFor='show_playbutton'>show play button</label>
              </li>
              <li>
                <input
                  id='show_bullets'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showBullets')}
                  checked={this.state.showBullets}/>
                  <label htmlFor='show_bullets'>show bullets</label>
              </li>
              <li>
                <input
                  id='show_thumbnails'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showThumbnails')}
                  checked={this.state.showThumbnails}/>
                  <label htmlFor='show_thumbnails'>show thumbnails</label>
              </li>
              <li>
                <input
                  id='show_navigation'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showNav')}
                  checked={this.state.showNav}/>
                  <label htmlFor='show_navigation'>show navigation</label>
              </li>
              <li>
                <input
                  id='show_index'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'showIndex')}
                  checked={this.state.showIndex}/>
                  <label htmlFor='show_index'>show index</label>
              </li>
              <li>
                <input
                  id='is_rtl'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'isRTL')}
                  checked={this.state.isRTL}/>
                  <label htmlFor='is_rtl'>is right to left</label>
              </li>
              <li>
                <input
                  id='slide_on_thumbnail_hover'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'slideOnThumbnailOver')}
                  checked={this.state.slideOnThumbnailOver}/>
                  <label htmlFor='slide_on_thumbnail_hover'>slide on mouse over thumbnails</label>
              </li>
              <li>
                <input
                  id='use_window_keydown'
                  type='checkbox'
                  onChange={this._handleCheckboxChange.bind(this, 'useWindowKeyDown')}
                  checked={this.state.useWindowKeyDown}/>
                  <label htmlFor='use_window_keydown'>use window keydown</label>
              </li>
            </ul>
          </div>

        </div>
      */}
      </section> 
      </div> 
            
    );
  }
}


export default Videos;
/*
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
*/